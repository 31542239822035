@import '../system.scss';

.dashboard {
  @media only screen and (max-width: $tabletWidth) {
    width: 570px !important;
    margin: auto;
  }
  @media only screen and (max-width: $mobileWidth) {
    width: 95% !important;
    margin: auto;
  }
  .dashboardTitle {
    margin: 10px 0 15px;
    @media only screen and (max-width: $tabletWidth) {
      margin: 20px 0 25px;
    }
  }
  .dashboardOverview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    @media only screen and (max-width: $tabletWidth) {
      display: grid;
      grid-template-columns: auto;
      gap: 10px;
    }
  }
  .addNewDataMenuMobileWrapper {
    display: none !important;
    @media only screen and (max-width: $tabletWidth) {
      display: unset !important;
    }
  }
}
.dashboardNoDataYet{
  max-width: 570px;
  padding: 20px;
}
