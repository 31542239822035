.generalLoading {
  padding-top: 20vh;
  text-align: center;
  .generalLoadingTitle{
    font-size: 1.76rem;
    font-family: 'Oswald';
  }
  .generalLoadingText {
    color: rgb(206, 206, 206);
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.3rem;
  }
}
