$tinyMobileWidth: 400px;
$mobileWidth: 600px;
$tabletWidth: 900px;
$desktopWidth: 1200px;

.linkFix{
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: Oswald;
  src: url('./fonts/Oswald.ttf');
}

// examples:
// 'tablet-v': 600px,
// 'tablet-h': 900px,
// 'desktop': 1200px,
// 'desktop-l': 1800px,
// 'desktop-xl': 1920px,
