@import '../../../system.scss';

.navBar{
  position: relative;
}

.appBarAlphaVersionNumber{
  color: gray;
  padding-left: 10px;
}

.appBarAddButtonWrapper{
  margin-left: auto;
  margin-right: 50px;
  @media only screen and (max-width: $tabletWidth) {
    display: none !important;
  }
}