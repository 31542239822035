@import '../../../system.scss';
$spaceBetween: 10px;

.registrationContainer {
  text-align: center;
  .registrationContainerStepper {
    padding: 27px 20px;
    max-width: 600px;
    margin: auto;
    @media only screen and (min-width: $mobileWidth) {
      background: none;
      box-shadow: none;
    }
  }
  .registrationContainerContent {
    padding: 25px !important;
  }
  .step1 {
    padding-top: 15vh;
    width: 80%;
    margin: 20px auto;
    
    @media only screen and (min-width: $mobileWidth) {
      width: 480px !important;
      margin: auto;
    }
    @media only screen and (max-width: $mobileWidth) {
      padding-top: 10vh;
    }
    @media only screen and (max-width: $tinyMobileWidth) {
      padding-top: 0vh;
    }
    .registerTitle {
      margin-bottom: 16px;
    }
    .registerTos {
      color: lightgray;
      font-size: 0.8rem;
      margin-bottom: 12px;
    }
    .registerSubmit {
      margin-bottom: 8px;
    }
  }
  .step2 {
    padding-top: 10vh;
    margin: auto;
    width: 80%;
    @media only screen and (min-width: $mobileWidth) {
      width: 480px !important;
      margin: auto;
    }
    @media only screen and (max-width: $tinyMobileWidth) {
      padding-top: 5vh;
    }
    .step2text {
      margin-bottom: 24px;
    }
  }
  .step3 {
    padding-top: 2vh;
    margin: auto;
    width: 80%;
    @media only screen and (min-width: $mobileWidth) {
      width: 480px !important;
      margin: auto;
    }
    .step3text {
      margin-bottom: 24px;
    }
  }
  .registerField {
    width: 100%;
    margin-bottom: $spaceBetween;
  }
  .pwdChecklist {
    .pwdChecklistItem {
      text-align: center;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -54px;
      margin-bottom: 8px;

      &.completed {
        color: #b2ff59;
      }
      .pwdChecklistItemText {
        margin-left: 6px;
      }
    }
  }
}
