@import '../../system.scss';

.desktopNavigationComponent {
  display: none !important;
  @media only screen and (max-width: $tabletWidth) {
    display: unset !important;
  }
}
.mobileNavigationComponent {
  @media only screen and (max-width: $tabletWidth) {
    display: none !important;
  }
}
