.backdropTest {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1550;
}

.speedDialWrapper {
  z-index: 1551 !important;
  position: relative;
  .speedDial {
    &.desktop {
      position: absolute;
    }
    &.mobile {
      //Appbar + space from appbar
      right: 20px;
      bottom: 56px + 20px;
      position: fixed;
      z-index: 1551 !important;
    }
    .MuiSpeedDialAction-staticTooltipLabel {
      width: max-content;
    }
    * {
      z-index: 1551 !important;
    }
  }
}
