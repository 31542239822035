.serverOffline {
  padding: 10% 20%;
  text-align: center;
  word-wrap: break-word;
  .serverOfflineText {
    font-weight: 600;
  }
  .serverOfflineAlphaTitle {
    color: rgb(255, 66, 66);
    font-style: italic;
  }
  .serverOfflineAlpha {
    color: rgb(255, 66, 66);
  }
}
