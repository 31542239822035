@import '../../../system.scss';
$spaceBetween: 10px;

.login{
  padding-top: 30vh;
  width: 80%;
  text-align: center;
  margin: 20px auto;
  @media only screen and (min-width: $mobileWidth) {
    width: 480px !important;
    margin: auto;
  }
  @media only screen and (max-width: $mobileWidth) {
    padding-top: 20vh;
  }
  @media only screen and (max-width: $tinyMobileWidth) {
    padding-top: 15vh;
  }
  .loginTitle{
    margin-bottom: $spaceBetween * 4;
  }
  .loginEmail{
    width: 100%;
    margin-bottom: $spaceBetween;
  }
  .loginPassword{
    width: 100%;
    margin-bottom: $spaceBetween;
  }
  .loginSubmit{
    margin-top: 12px;
  }
}
