@import '../../../system.scss';

$animationLength: 0.2s;
$border: 3px;

@keyframes cardPop {
  0% {
    right: 0;
    bottom: 0;
  }
  100% {
    right: -$border * 1.5;
    bottom: -$border * 1.5;
  }
}
@keyframes cardPopOut {
  0% {
    right: -$border * 1.5;
    bottom: -$border * 1.5;
  }
  100% {
    right: 0;
    bottom: 0;
  }
}
@keyframes cardPopChild {
  0% {
    bottom: 0;
    right: 0;
  }
  100% {
    bottom: 7.5px;
    right: 7.5px;
  }
}
@keyframes cardPopChildOut {
  0% {
    bottom: 7.5px;
    right: 7.5px;
  }
  100% {
    bottom: 0;
    right: 0;
  }
}

.dashboardCardBorder {
  @media only screen and (min-width: $tabletWidth) {
    animation: cardPopOut $animationLength;
  }
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  border-bottom: solid $border transparent;
  border-radius: 4px;

  &:before {
    content: '';
    position: absolute;
    top: $border;
    right: -$border;
    bottom: -$border;
    left: $border;
    z-index: -1;
    border-radius: inherit;
    @media only screen and (max-width: $tabletWidth) {
      left: 1px;
      right: 1px;
    }
  }
  &.red {
    &:before {
      background: linear-gradient(to right, #89216b, #da4453);
    }
  }
  &.green {
    &:before {
      background: linear-gradient(to right, #00b09b, #96c93d);
    }
  }
  &.yellow {
    &:before {
      background: linear-gradient(to right, yellow, orange);
    }
  }
  &.blue {
    &:before {
      background: linear-gradient(to right, #00b4db, #0083b0);
    }
  }
  &:hover {
    @media only screen and (min-width: $tabletWidth) {
      animation: cardPop $animationLength;
      right: -$border * 1.5;
      bottom: -$border * 1.5;
      .dashboardCard {
        animation: cardPopChild $animationLength;
        bottom: 7.5px;
        right: 7.5px;
      }
    }
  }
}
.dashboardCard {
  @media only screen and (min-width: $tabletWidth) {
    animation: cardPopChildOut $animationLength;
    width: 300px;
  }
  position: relative;
  bottom: 0;
  right: 0;
  padding: 10px 15px 30px;
}
