.MobileNavigationBarWrapper{
  margin-top: 56px;
}

.MobileNavigationBar {
  width: 100vw;
  position: fixed;
  bottom: 0;
  a{

    text-decoration: none;
    button{
      padding:8px;
    }
    &.activeItem{
      button{
        padding:4px 8px;
      }
      font-size: 2rem !important;
      background-color: #212121;
      .MuiBottomNavigationAction-label{
        font-size: 0.8rem !important;
        opacity: 1;
        color: #42a5f5 !important; 
      }
      .MuiSvgIcon-root{
        font-size: 1.8rem !important;
        color: #42a5f5 !important; 

      }
    }
  }
  .MuiBottomNavigationAction-label{
    opacity: 1;
  }
}

/* This is for v2 */
// .mobileAddNewDataMenuWrapper{
//   position: relative;
//   // width: 100%;
  
// }